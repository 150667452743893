import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import {
  BehaviorSubject,
  firstValueFrom,
  map,
  Observable,
  switchMap
} from 'rxjs';
import { OverviewTotals } from '../interfaces/overview-totals.interface';
import { IBusinessCaseGeneration } from '../models/business-case-generation';
import { BusinessCaseSetup } from '../interfaces/business-case-setup.interface';
import { UpdateBusinessCasePayload } from '../interfaces/update-business-case-payload.interface';
import { IBusinessCaseTableFilter } from '../interfaces/business-case-table-filter.interface';
import { IBusinessCaseDetails } from '../interfaces/business-case-details.interface';
import { IBusinessCaseAccount } from '../interfaces/collaborator.interface';
import { IBusinessCaseOverview } from '../interfaces/business-case-overview.interface';
import { IActivityLog } from '../interfaces/activity-log.interface';
import { IBusinessCaseStageConfig } from '../interfaces/business-case-stage-config.interface';
import { format, subDays } from 'date-fns';
import { BusinessCaseReviewPayload } from '../interfaces/business-case-review-payload.interface';
import { IBusinessCaseSoftServiceCostOverview } from '../models/business-case-service-cost-overview.interface';
import { IBusinessCaseSoftServiceQuote } from '../models/business-case-soft-service-quote';
import { BuildingData } from '../interfaces/building-data.interface';
import { UserBusinessCase } from '../interfaces/user-business-case.interface';
import { UpdateUserBusinessCasesPayload } from '../interfaces/update-user-business-cases-payload.interface';
import { ParamMap } from '@angular/router';
import { IBusinessCaseEngineeringServiceQuote } from '../models/business-case-engineering-service-quote';

@Injectable({
  providedIn: 'root'
})
export class BusinessCaseService {
  public businessCaseSetup!: BusinessCaseSetup;
  public defaultRangeDaysCount = 7;
  public defaultFilter: IBusinessCaseTableFilter = {
    currentPage: 1,
    size: 200,
    sortBy: 'initiationDate',
    sortDirection: 'Desc',
    startDate: format(
      subDays(new Date(), this.defaultRangeDaysCount),
      'yyyy-MM-dd'
    ), // yyyy-MM-dd or null to ignore
    endDate: format(new Date(), 'yyyy-MM-dd'), // yyyy-MM-dd or null to ignore
    filters: {
      overdue: false,
      delayed: false,
      businessCaseStatus: null,
      stageName: ''
    },
    searchTerm: '' // searches business case name
  };
  public businessCaseTableFilter: BehaviorSubject<IBusinessCaseTableFilter> =
    new BehaviorSubject(this.defaultFilter);

  public businessCaseTableFilter$: Observable<IBusinessCaseTableFilter> =
    this.businessCaseTableFilter.asObservable();

  private baseUrl: string = environment.apiUrl;
  private readonly _http: HttpClient = inject(HttpClient);

  public async initializeBusinessCase(businessCaseType: string): Promise<void> {
    await firstValueFrom(
      this._http.post<BusinessCaseSetup>(`${this.baseUrl}/business-cases`, {
        businessCaseType
      })
    ).then((res) => {
      this.businessCaseSetup = res;
    });
  }

  public generateBusinessCaseTable(): Observable<IBusinessCaseGeneration> {
    return this.businessCaseTableFilter$.pipe(
      switchMap((filterData) =>
        this._http.put<IBusinessCaseGeneration>(
          `${this.baseUrl}/business-cases/table`,
          filterData
        )
      )
    );
  }

  public fetchBusinessCaseById(id: number): Observable<IBusinessCaseDetails> {
    return this._http.get<IBusinessCaseDetails>(
      `${this.baseUrl}/business-cases/${id}`
    );
  }

  public fetchDoc(
    businessCaseId: number,
    drawingId: number,
    drawingUrl: string
  ): Observable<Blob> {
    return this._http.get(
      `${this.baseUrl}/business-cases/${businessCaseId}/documents/${drawingId}/${drawingUrl}`,
      {
        responseType: 'blob'
      }
    );
  }

  public fetchOverviewTotals(): Observable<OverviewTotals> {
    return this.businessCaseTableFilter$.pipe(
      switchMap((filterData) =>
        this._http.get<OverviewTotals>(
          `${this.baseUrl}/business-cases/overview/${filterData.startDate}/${filterData.endDate}`
        )
      )
    );
  }

  public updateBusinessCase(
    payload: UpdateBusinessCasePayload
  ): Observable<void> {
    return this._http.put<void>(`${this.baseUrl}/business-cases`, payload);
  }

  public fetchBusinessCaseAccounts(): Observable<Array<IBusinessCaseAccount>> {
    return this._http.get<Array<IBusinessCaseAccount>>(
      `${this.baseUrl}/business-cases/users`
    );
  }

  public fetchBusinessCaseAccountsByProvince(
    province: string
  ): Promise<Array<IBusinessCaseAccount>> {
    return firstValueFrom(
      this._http.get<Array<IBusinessCaseAccount>>(
        `${this.baseUrl}/business-cases/users/` + province
      )
    );
  }

  public fetchBusinessCaseOverview(
    id: number
  ): Observable<IBusinessCaseOverview> {
    return this._http.get<IBusinessCaseOverview>(
      `${this.baseUrl}/business-cases/${id}/overview`
    );
  }

  public fetchActivityLogs(id: number): Observable<Array<IActivityLog>> {
    return this._http.get<Array<IActivityLog>>(
      `${this.baseUrl}/business-cases/${id}/activities`
    );
  }

  public addNewComment(payload: {
    id: number;
    comment: string;
  }): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/business-cases/comments`,
      payload
    );
  }

  public fetchBusinessCaseSoftService(
    businessCaseId: number
  ): Promise<IBusinessCaseSoftServiceCostOverview> {
    return firstValueFrom(
      this._http.get<IBusinessCaseSoftServiceCostOverview>(
        `${this.baseUrl}/business-cases/${businessCaseId}/overview/soft-services`
      )
    );
  }

  public fetchBusinessCaseStagesConfigs(
    businessCaseId: number
  ): Observable<Array<IBusinessCaseStageConfig>> {
    return this._http.get<Array<IBusinessCaseStageConfig>>(
      `${this.baseUrl}/business-cases/${businessCaseId}/stages`
    );
  }

  public submitBusinessCaseForReview(payload: {
    businessCaseId: number;
    businessCaseStageId: number;
  }): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/business-cases/status/review`,
      payload
    );
  }

  public deleteBusinessCase(businessCaseId: number): Observable<void> {
    return this._http.delete<void>(
      `${this.baseUrl}/business-cases/${businessCaseId}`
    );
  }

  public rejectBusinessCase(
    payload: BusinessCaseReviewPayload
  ): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/business-cases/review`,
      payload
    );
  }

  public approveBusinessCase(
    payload: BusinessCaseReviewPayload
  ): Observable<void> {
    return this._http.put<void>(
      `${this.baseUrl}/business-cases/review`,
      payload
    );
  }

  public fetchBusinessCaseSoftServiceQuote(
    businessCaseId: number
  ): Observable<IBusinessCaseSoftServiceQuote> {
    return this._http.get<IBusinessCaseSoftServiceQuote>(
      `${this.baseUrl}/business-cases/${businessCaseId}/soft-services/quote`
    );
  }

  public fetchBusinessCaseHardServiceQuote(
    businessCaseId: number
  ): Observable<IBusinessCaseEngineeringServiceQuote> {
    return this._http.get<IBusinessCaseEngineeringServiceQuote>(
      `${this.baseUrl}/business-cases/${businessCaseId}/engineering-services/quote`
    );
  }

  public resolveBCDelay(payload: FormData): Observable<any> {
    return this._http.put(
      `${this.baseUrl}/business-cases/delayed/reason`,
      payload
    );
  }

  public downloadBCDocument(docUrl: string): Observable<any> {
    return this._http.get(`${this.baseUrl}/${docUrl}`, {
      observe: 'response',
      responseType: 'blob'
    });
  }

  public fetchBuildingList(): Observable<Array<BuildingData>> {
    return this._http
      .get<{ RESULT: Array<BuildingData>; ERROR: any }>(
        `${this.baseUrl}/business-cases/sites`
      )
      .pipe(map((res) => res.RESULT));
  }

  public fetchUserBusinessCases(
    userId: number
  ): Promise<Array<UserBusinessCase>> {
    return firstValueFrom(
      this._http.get<Array<UserBusinessCase>>(
        `${this.baseUrl}/accounts/${userId}/business-cases`
      )
    );
  }

  public updateUserBusinessCases(
    userId: number,
    payload: Array<UpdateUserBusinessCasesPayload>
  ): Promise<Array<UserBusinessCase>> {
    return firstValueFrom(
      this._http.put<Array<UserBusinessCase>>(
        `${this.baseUrl}/accounts/${userId}/business-cases`,
        payload
      )
    );
  }

  public isReadOnlyView(routeParams: ParamMap): boolean {
    const isReadOnlyView =
      routeParams.get('read-only') === undefined ||
      routeParams.get('read-only') === null
        ? false
        : true;

    return isReadOnlyView;
  }

  public isEditor(
    bcStagesConfigs: Array<IBusinessCaseStageConfig>,
    bcDetails: IBusinessCaseDetails, //TODO remove
    currentStageName = ''
  ): boolean {
    return bcStagesConfigs.find(
      (config) => config.stageName === currentStageName
    )?.canEdit as boolean;
  }

  public isReviewer(
    bcStagesConfigs: Array<IBusinessCaseStageConfig>,
    bcDetails: IBusinessCaseDetails, //TODO remove
    currentStageName = ''
  ): boolean {
    return bcStagesConfigs.find(
      (config) => config.stageName === currentStageName
    )?.canRead as boolean;
  }

  public includesSoftServices(businessCase: IBusinessCaseDetails): boolean {
    return (
      businessCase?.businessCaseType! === 'ENGINEERING_AND_SOFT_SERVICES' ||
      businessCase?.businessCaseType! === 'SOFT_SERVICES' ||
      businessCase?.businessCaseType! === 'CATERING' ||
      businessCase?.businessCaseType! === 'ATM_SINGLE_BC' ||
      businessCase?.businessCaseType! === 'ATM_GROUP_BC'
    );
  }

  public includesEngineeringServices(
    businessCase: IBusinessCaseDetails
  ): boolean {
    return (
      businessCase?.businessCaseType! === 'ENGINEERING_SERVICES' ||
      businessCase?.businessCaseType! === 'ENGINEERING_AND_SOFT_SERVICES' ||
      businessCase?.businessCaseType! === 'ATM_SINGLE_BC' ||
      businessCase?.businessCaseType! === 'ATM_GROUP_BC'
    );
  }
}
