import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '@env/environment';
import { firstValueFrom } from 'rxjs';
import {
  BusinessCaseBuildingEquipmentResultModel,
  IEquipmentRetrievalModel
} from '../models/asset-list-equipment';
import { IAssetListParams } from '../models/asset-list-params';
import { IAssetEquipmentVerification } from '../models/asset-equipment-verification.interface';
import { IGetAssetForPricingResponse } from '../interfaces/get-assets-for-pricing-response.interface';
import { IBusinessCaseAsset } from '../interfaces/business-case-asset.interface';
import { IGetVerifiedAssetsResponse } from '../interfaces/get-verified-assets-response.interface';
import { IAssetDecline } from '../models/asset-decline.interface';
import { IDeclineAsset } from '../interfaces/deline-asset.interface';
import { IProcuredAssetPrices } from '../interfaces/procured-asset-prices.interfaces';
import { IProcurementPriceList } from '../interfaces/procurement-price-list.interface';

@Injectable({
  providedIn: 'root'
})
export class AssetListService {
  private baseUrl: string = environment.apiUrl;

  constructor(private _http: HttpClient) {}

  public async getAssetListEquipmentData({
    id,
    page = 0,
    size = 1000,
    searchTerm = '',
    filter = ''
  }: IAssetListParams): Promise<BusinessCaseBuildingEquipmentResultModel> {
    return firstValueFrom(
      this._http.get<BusinessCaseBuildingEquipmentResultModel>(
        `${this.baseUrl}/business-cases/${id}/equipment/filter?page=${page}&size=${size}&searchTerm=${searchTerm}&filter=${filter}&getAll=true`
      )
    );
  }

  public assetVerification(
    data: IAssetEquipmentVerification,
    businessCaseId: number
  ) {
    return firstValueFrom(
      this._http.post(
        `${this.baseUrl}/business-cases/${businessCaseId}/assets`,
        data
      )
    );
  }

  public async getAssetsForPricing(
    businessCaseId: number,
    page = 0,
    size = 200,
    filter = '',
    searchTerm = ''
  ): Promise<IGetAssetForPricingResponse> {
    return firstValueFrom(
      this._http.get<IGetAssetForPricingResponse>(
        `${this.baseUrl}/business-cases/${businessCaseId}/assets/pricing/filter?page=${page}&size=${size}`
      )
    );
  }

  public async getVerifiedAssets(
    businessCaseId: number,
    page = 0,
    size = 1000,
    filter = '',
    searchTerm = ''
  ): Promise<IGetVerifiedAssetsResponse> {
    return firstValueFrom(
      this._http.get<IGetVerifiedAssetsResponse>(
        `${this.baseUrl}/business-cases/${businessCaseId}/assets/filter?page=${page}&size=${size}&filter=${filter}&searchTerm=${searchTerm}`
      )
    );
  }

  public async declineAssetQuote(
    assetId: number,
    payload: IAssetDecline
  ): Promise<void> {
    return firstValueFrom(
      this._http.put<void>(
        `${this.baseUrl}/business-cases/assets/${assetId}/decline`,
        payload
      )
    );
  }

  public updateAssetPricing(
    businessCaseId: number,
    payload: IBusinessCaseAsset
  ) {
    return firstValueFrom(
      this._http.put(
        `${this.baseUrl}/business-cases/${businessCaseId}/assets/pricing`,
        payload
      )
    );
  }

  public replaceAsset(
    businessCaseId: number,
    payload: IBusinessCaseAsset,
    equipmentId: string
  ) {
    return firstValueFrom(
      this._http.put(
        `${this.baseUrl}/business-cases/${businessCaseId}/assets/${equipmentId}`,
        payload
      )
    );
  }

  public fetchEquipmentById(
    businessCaseId: number,
    equipmentId: string
  ): Promise<Array<IEquipmentRetrievalModel>> {
    return firstValueFrom(
      this._http.get<Array<IEquipmentRetrievalModel>>(
        `${this.baseUrl}/business-cases/${businessCaseId}/equipment/${equipmentId}`
      )
    );
  }

  public declineAsset(assetId: number, payload: IDeclineAsset) {
    return firstValueFrom(
      this._http.put(
        `${this.baseUrl}/business-cases/assets/${assetId}/decline`,
        payload
      )
    );
  }

  public approveProcuredAssets(businessCaseId: number, payload: Array<IProcurementPriceList>) {
    return firstValueFrom(
      this._http.put(
        `${this.baseUrl}/business-cases/${businessCaseId}/assets/approve`,
        payload
      )
    );
  }

  public submitArrayAssetPrices(businessCaseId: number, payload: Array<IProcuredAssetPrices>) {
    return firstValueFrom(
      this._http.put(
        `${this.baseUrl}/business-cases/${businessCaseId}/assets/update-prices`, payload)
      );
    }

  public submitIndividualAssetPrices(
    businessCaseId: number,
    payload: IProcuredAssetPrices
  ) {
    return firstValueFrom(
      this._http.put(
        `${this.baseUrl}/business-cases/${businessCaseId}/assets/update-prices`,
        payload
      )
    );
  }

  public flagIndividualAsset(
    assetId: number,
    payload: { assetPriceStatus: string }
  ) {
    return firstValueFrom(
      this._http.put(
        `${this.baseUrl}/business-cases/assets/${assetId}/verify-prices`,
        payload
      )
    );
  }

}
